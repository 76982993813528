<template>
  <b-card class="auditoria-container">
    <b-button v-b-toggle.collapse-1 variant="primary" id="filter-button">
      Filtros
      <!--<span v-show="this.arValidation.length > 0">contador</span> !-->
    </b-button>

    <b-collapse id="collapse-1" class="mt-2">
      <template>
        <div>
          <form ref="anyName" class="filtros-auditoria">
            <div class="p-1 flex-fill">
              <input-date-time id="de" :label="$t('AUDITORIA.DE')" v-model="form.DataDe" @onChange="(value) => funcaoteste(value)" />
            </div>
            <div class="p-1 flex-fill">
              <input-date-time id="ate" :label="$t('AUDITORIA.ATE')" v-model="form.DataAte" @onChange="(value) => funcaoteste(value)" />
            </div>

            <div class="p-1 flex-fill">
              <input-select :options="optionsValue" :label="$t('AUDITORIA.TIPO')" v-model="form.TipoAuditoria" />
            </div>

            <div class="d-inline-flex flex-fill">
              <div class="p-1 flex-fill">
                <input-select
                  :options="entidades"
                  :label="$t('AUDITORIA.ENTIDADE')"
                  @input="
                    (entidade) => {
                      getCamposDropdown(entidade);
                    }
                  "
                  v-model="form.Entidade"
                />
              </div>
              <div class="p-1 flex-fill">
                <input-select :options="camposDrop" :label="$t('AUDITORIA.CAMPO')" v-model="form.Campo" />
              </div>
            </div>

            <div class="d-flex flex-row justifiy-content-between flex-fill">
              <div class="p-1 flex-fill">
                <input-select :options="usuariosDrop" :label="$t('AUDITORIA.NOME_USUARIO')" :placeholder="$t('PLACEHOLDER.NOME_USUARIO')" v-model="form.UsuarioId" />
              </div>
            </div>

            <div class="p-1 flex-fill">
              <input-text :label="$t('AUDITORIA.VALOR')" :placeholder="$t('PLACEHOLDER.VALOR')" v-model="form.Valor" />
            </div>
          </form>

          <hr />

          <div class="buttons-filter">
            <b-button
              class="button-filter"
              @click="
                () => {
                  limparFiltro();
                }
              "
              >Resetar</b-button
            >
            <b-button
              class="button-filter"
              variant="primary"
              v-on:click="
                () => {
                  filtrarAuditoria();
                }
              "
              >Aplicar</b-button
            >
          </div>
        </div>
      </template>
    </b-collapse>
    <div class="header-table-operacoes">
      <b-form-group label="Por Pagina" label-for="per-page-select" label-class="fw-medium" class="por-pagina">
        <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions"></b-form-select>
      </b-form-group>
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
    </div>
    <b-table head-variant="light" hover :items="items" :fields="campos"  responsive="sm" class="h-overflow table-auditoria">
      <template v-slot:cell(dataCriacao)="item">
        {{ dataFormatada(item.dataCriacao) }}
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import helpers from "@/common/utils/helpers";
import InputDateTime from "@/components/inputs/InputDateTime";
import InputSelect from "@/components/inputs/InputSelect";
import InputText from "@/components/inputs/InputText";
import AuditoriaService from "@/common/services/auditoria/auditoria.service";
import UsuarioService from "@/common/services/usuario/usuario.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";

export default {
  name: "Index",
  components: {
    InputDateTime,
    InputSelect,
    InputText,
  },
  data() {
    return {
      permissions: helpers.validarAcesso("Usuario"),
      form: {},
      optionsValue: [
        { value: null, text: this.$t("AUDITORIA.SELECIONE") },
        { value: "Adicionado", text: this.$t("AUDITORIA.ADICIONADO") },
        { value: "Modificado", text: this.$t("AUDITORIA.MODIFICADO") },
        { value: "Excluido", text: this.$t("AUDITORIA.DELETADO") },
      ],
      entidades: [{ value: null, text: this.$t("AUDITORIA.SELECIONE") }],
      camposDrop: [{ value: null, text: this.$t("AUDITORIA.SELECIONE") }],
      usuariosDrop: [{ value: null, text: this.$t("AUDITORIA.SELECIONE") }],
      campos: [
        {
          key: "dataCriacao",
          label: this.$t("AUDITORIA.DATA_CRIACAO"),
        },
        {
          key: "tipo",
          label: this.$t("AUDITORIA.TIPO_AUDITORIA"),
        },
        {
          key: "entidade",
          label: this.$t("AUDITORIA.ENTIDADE"),
        },
      ],
      items: [],
      totalRows: 5,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: this.$t("GERAL.PAGINACAO") }],
    };
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [{ titulo: this.$t("MENU.AUDITORIA") }]);
    // this.listar();
    this.getEntidadesDropdown();
    this.getUsuarioDropdown();
  },
  methods: {
    dataFormatada(data) {
      return helpers.formatarDataBr(data);
    },
    limparFiltro() {
      this.$refs.anyName.reset();

      for (const key in this.form) {
        if (Object.hasOwnProperty.call(this.form, key)) {
          if (key == "DataDe" || key == "DataAte") {
            this.$set(this.form, key, new Date());
          } else {
            this.$set(this.form, key, undefined);
          }
        }
      }
    },
    getEntidadesDropdown() {
      this.entidades = [{ value: null, text: this.$t("AUDITORIA.SELECIONE") }];
      this.$store.dispatch(START_LOADING);
      AuditoriaService.entidadesDropdown({})
        .then((result) => {
          this.entidades = [...this.entidades, ...result];
        })
        . catch(() => {
          
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    filtrarAuditoria(porPagina, paginaAtual) {
      this.$store.dispatch(START_LOADING);
      let formSend = { ...this.from, ...{ NumeroDaPagina: (paginaAtual || this.currentPage) - 1, TamanhoDaPagina: porPagina || this.perPage } };

      AuditoriaService.auditoria(formSend)
        .then((result) => {
          this.items = result.data.data.itens;
        })
        . catch(() => {
          
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getCamposDropdown() {
      if (!this.form.Entidade) return;

      this.camposDrop = [{ value: null, text: this.$t("AUDITORIA.SELECIONE") }];

      this.$store.dispatch(START_LOADING);
      AuditoriaService.camposDropdown(this.form)
        .then((result) => {
          this.camposDrop = [...this.camposDrop, ...result];
        })
        . catch(() => {
          
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getUsuarioDropdown() {
      this.$store.dispatch(START_LOADING);
      UsuarioService.usuarioDropdown(this.form)
        .then((result) => {
          this.usuariosDrop = [...this.usuariosDrop, ...result];
        })
        . catch(() => {
          
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
  computed: {
    contador() {
      let arValidation = [];

      arValidation.push(this.form.de);
      arValidation.push(this.form.ate);
      arValidation.push(this.form.tipo);
      arValidation.push(this.form.entidade);
      arValidation.push(this.form.campo);
      arValidation.push(this.form.idEntidade);
      arValidation.push(this.form.nomeUsuario);
      arValidation.push(this.form.valor);

      return arValidation.filter((elem) => elem != null).length;
    },
  },
};
</script>
