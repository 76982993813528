import ApiService from "@/common/api/api.service";
//import i18n from "../../../i18n/i18n";
import helpers from "../../../common/utils/helpers";

const AuditoriaService = {
  auditoria(form) {
    let params = helpers.serialize(form);
    return ApiService.get(`auditoria${params}`);
  },

  async entidadesDropdown() {
    let data = await ApiService.get(`auditoria/entidades-dropdown`);

    let entidade = data.data.data.entidades;

    let retorno = [];

    for (let i = 0; i < entidade.length; i++) {
      retorno.push({
        value: entidade[i].nome,
        text: entidade[i].nome,
      });
    }

    return retorno;
  },

  async camposDropdown(form) {
    let data = await ApiService.get(`auditoria/campos-dropdown?entidade=${form.Entidade}`);

    let campos = data.data.data.campos;

    let retorno = [];

    for (let i = 0; i < campos.length; i++) {
      if (campos[i].nome != "ConcurrencyStamp" && campos[i].nome != "AccessFailedCount")
        retorno.push({
          value: campos[i].nome,
          text: campos[i].nome,
        });
    }

    return retorno;
  },
};

export default AuditoriaService;
